import React, { useContext, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Link } from 'react-router-dom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function Home(props) {

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [openinfo, setOpenInfo] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const toggleInfo = (index) => {
        if (openinfo === index) {
            setOpenInfo(null);
        } else {
            setOpenInfo(index);
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Set autoplay speed (2000ms = 2 seconds)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const EmergencyServices = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Set autoplay speed (2000ms = 2 seconds)
        responsive: [
            {
                breakpoint: 1024,
                EmergencyServices: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                EmergencyServices: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            {/* Navbar Start */}
            <div className="container-fluid position-relative p-0">
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active home-section">
                            <img className="w-100" src="assets/img/mainslide-01.jpg" alt="Image" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: 900 }}>
                                    <h4 className="text-white text-uppercase mb-3 animated slideInDown">keeping you wired</h4>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">We’re the Current<br />Specialist!</h1>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item home-section">
                            <img className="w-100" src="assets/img/mainslide-02.jpg" alt="Image" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: 900 }}>
                                    <h4 className="text-white text-uppercase mb-3 animated slideInDown">making our clients happier</h4>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">Best Services for<br />Your Family</h1>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item home-section">
                            <img className="w-100" src="assets/img/mainslide-03.jpg" alt="Image" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: 900 }}>
                                    <h4 className="text-white text-uppercase mb-3 animated slideInDown">we can light everything</h4>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">Nothing is<br />Impossible for Us</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            {/* Navbar End */}

            <div>
                {/* Full Screen Search Start */}
                <div className="modal fade" id="searchModal" tabIndex={-1}>
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content" style={{ background: 'rgba(9, 30, 62, .7)' }}>
                            <div className="modal-header border-0">
                                <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body d-flex align-items-center justify-content-center">
                                <div className="input-group" style={{ maxWidth: 600 }}>
                                    <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
                                    <button className="btn btn-primary px-4"><i className="bi bi-search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Full Screen Search End */}

                {/* About Start */}
                <div className="container-fluid pt-5 wow fadeInUp mt-5" data-wow-delay="0.1s">
                    <div className="container pt-5">
                        <div className="layout01 layout01__revers layout01__small-layout layout01__img-more">
                            <div className="layout01__img" style={{ order: "0" }}>
                                <div className="tt-img-main">
                                    <img fetchpriority="high" decoding="async" width={720} height={552} src="assets/img/NewElectronic/worker-is-working-factory.jpg" className="attachment-full size-full" alt sizes="(max-width: 720px) 100vw, 720px" />					</div>
                                <div className="tt-img-more left-bottom right-side">
                                    <img decoding="async" width={436} src="assets/img/NewElectronic/woman-changing-light-bulb-hanging-lamp-home-house-owner-cleaning-changing-vintage-light-bulb-house-main.jpg" className="attachment-full size-full" alt sizes="(max-width: 436px) 100vw, 436px" />					</div>
                            </div>
                            <div className="layout01__content">
                                <div className="layout01__content-wrapper-left">
                                    <div className="section-title" style={{ textAlign: "start" }}>
                                        <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
                                        <h1>Experienced and Reliable Electrical Contractors</h1 >
                                    </div>
                                    <p>
                                        We pride ourselves on providing services backed by a 100% satisfaction guarantee. Our skilled electricians handle everything from installing new outdoor security lighting to comprehensive electrical setups and wiring repairs.
                                    </p>

                                    <h6 className='pt-3'>Our Services Include:
                                    </h6>
                                    <ul className="tt-list01">
                                        <li><i class="fa-solid fa-check"></i>Full-service electrical layout and design</li>
                                        <li><i class="fa-solid fa-check"></i>Electrical setup and installation</li>
                                        <li><i class="fa-solid fa-check"></i>Wiring installation and upgrades Wiring repairs</li>
                                        <li><i class="fa-solid fa-check"></i>Virtually any electrical needs you have – just ask!</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* About End */}

                {/* Skew Category Block Start */}
                <div className="section-indent overflow-hidden">
                    <div className="tt-slideinfo-wrapper slick-type01">
                        <div
                            className={`tt-slideinfo wide ${openinfo === 0 ? 'open-info' : ''} ${hoveredIndex === 1 || hoveredIndex === 2 ? 'short' : hoveredIndex === 0 ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter(0)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="tt-item__bg">
                                <div className="tt-item__bg-img lazyloaded" style={{ backgroundImage: 'url("assets/img/slideinfo-01.jpg")', objectFit: "cover", width: "calc(100% + 76px)" }} />
                                <div className="tt-item__bg-top" />
                            </div>
                            <div className="tt-item__content">
                                <div className="tt-item__title">
                                    <span className="tt-icon">
                                        <img decoding="async" src="https://smartdata.tonytemplates.com/electrician-v2/wp-content/plugins/electricity-core/assets/images/slideinfo-marker.png" className="ls-is-cached lazyloaded" alt />
                                    </span>
                                    <span className="tt-text">Commercial</span>
                                </div>
                                <div className="tt-item__description">
                                    Our services include full wiring setups and complete electrical installations. We ensure the highest level of responsiveness and reliability, utilizing online job management and reporting systems. Our experienced contractors nationwide guarantee that your premises are always well-maintained and compliant with all regulations.
                                </div>
                                <div className="tt-item__btn">
                                    <a style={{ cursor: "pointer" }} onClick={() => toggleInfo(0)}>
                                        {openinfo === 0 ? '-' : '+'}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`tt-slideinfo wide ${openinfo === 1 ? 'open-info' : ''} ${hoveredIndex === 0 || hoveredIndex === 2 ? 'short' : hoveredIndex === 1 ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="tt-item__bg">
                                <div className="tt-item__bg-img lazyloaded" style={{ backgroundImage: 'url("assets/img/slideinfo-02.jpg")', objectFit: "cover", width: "calc(100% + 76px)" }} />
                                <div className="tt-item__bg-top" />
                            </div>
                            <div className="tt-item__content">
                                <div className="tt-item__title">
                                    <span className="tt-icon">
                                        <img decoding="async" src="https://smartdata.tonytemplates.com/electrician-v2/wp-content/plugins/electricity-core/assets/images/slideinfo-marker.png" className="ls-is-cached lazyloaded" alt />
                                    </span>
                                    <span className="tt-text">Industrial</span>
                                </div>
                                <div className="tt-item__description">
                                    We offer the highest level of responsiveness and reliability, including on-line job management and reporting. Our highly experienced contractors across the nation ensure that your premises are always maintained and compliant.
                                </div>
                                <div className="tt-item__btn">
                                    <a style={{ cursor: "pointer" }} onClick={() => toggleInfo(1)}>
                                        {openinfo === 1 ? '-' : '+'}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`tt-slideinfo wide ${openinfo === 2 ? 'open-info' : ''} ${hoveredIndex === 0 || hoveredIndex === 1 ? 'short' : hoveredIndex === 2 ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter(2)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="tt-item__bg">
                                <div className="tt-item__bg-img lazyloaded" style={{ backgroundImage: 'url("assets/img/slideinfo-03.jpg")', objectFit: "cover", width: "calc(100% + 76px)" }} />
                                <div className="tt-item__bg-top" />
                            </div>
                            <div className="tt-item__content">
                                <div className="tt-item__title">
                                    <span className="tt-icon">
                                        <img decoding="async" src="https://smartdata.tonytemplates.com/electrician-v2/wp-content/plugins/electricity-core/assets/images/slideinfo-marker.png" className="ls-is-cached lazyloaded" alt />
                                    </span>
                                    <span className="tt-text">Residential</span>
                                </div>
                                <div className="tt-item__description">
                                    We offer the highest level of responsiveness and reliability, including on-line job management and reporting. Our highly experienced contractors across the nation ensure that your premises are always maintained and compliant.
                                </div>
                                <div className="tt-item__btn">
                                    <a style={{ cursor: "pointer" }} onClick={() => toggleInfo(2)}>
                                        {openinfo === 2 ? '-' : '+'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Skew Category Block End */}

                {/* Our Team Start */}
                <div className="container-fluid pt-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="section-title max-width-01">
                            <h5 className="fw-bold text-primary text-uppercase">24/7 Electrician Services – Safe and Efficient</h5>
                            <h1 >We are a Full Service Electrical Contractor
                            </h1 >
                        </div>
                        <div>
                            <Slider {...settings}>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-4">
                                    <a href="#" className="tt-box05" tabIndex={-1}>
                                        <div className="tt-box05__img">
                                            <img loading="lazy" decoding="async" style={{ width: "140px" , objectFit: "cover"}} width={224} height={282} src="assets/img/box02-img01.jpg" className="tt-img-main" alt />
                                            <img loading="lazy" decoding="async" style={{ width: "145px" }} width={224} height={332} src="assets/img/mask-img02-202x300.png" className="tt-img-mask" alt sizes="(max-width: 224px) 100vw, 224px" />
                                        </div>
                                        <div className="tt-box05__title">
                                            <div className="tt-text-01">Complete Wiring Installation</div>
                                            <div className="tt-text-02">Full wiring setups for franchise outlets, commercial spaces, and residential properties, ensuring a robust and efficient electrical infrastructure.</div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12 mb-4'>
                                    <a href="#" className="tt-box05" tabIndex={-1}>
                                        <div className="tt-box05__img">
                                            <img loading="lazy" decoding="async" style={{ width: "140px" , objectFit: "cover"}} width={224} height={282} src="assets/img/box02-img02.jpg" className="tt-img-main" alt />
                                            <img loading="lazy" decoding="async" style={{ width: "145px" }} width={224} height={332} src="assets/img/mask-img02-202x300.png" className="tt-img-mask" alt sizes="(max-width: 224px) 100vw, 224px" />
                                        </div>
                                        <div className="tt-box05__title">
                                            <div className="tt-text-01">Rewiring Services</div>
                                            <div className="tt-text-02">Comprehensive rewiring solutions for updating and upgrading existing electrical systems in franchises, commercial buildings, and homes.</div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12 mb-4'>
                                    <a href="#" className="tt-box05" tabIndex={-1}>
                                        <div className="tt-box05__img">
                                            <img loading="lazy" decoding="async" style={{ width: "140px" , objectFit: "cover"}} width={224} height={282} src="assets/img/box02-img03.jpg" className="tt-img-main" alt />
                                            <img loading="lazy" decoding="async" style={{ width: "145px" }} width={224} height={332} src="assets/img/mask-img02-202x300.png" className="tt-img-mask" alt sizes="(max-width: 224px) 100vw, 224px" />
                                        </div>
                                        <div className="tt-box05__title">
                                            <div className="tt-text-01">Electrical Repairs</div>
                                            <div className="tt-text-02">Prompt and reliable repair services for electrical faults and issues across franchise outlets, commercial premises, and residential properties.</div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12 mb-4'>
                                    <a href="#" className="tt-box05" tabIndex={-1}>
                                        <div className="tt-box05__img">
                                            <img loading="lazy" decoding="async" style={{ width: "140px", objectFit: "cover", height: "175px" }} width={224} height={282} src="assets/img/layout01-img02.jpg" className="tt-img-main" alt />
                                            <img loading="lazy" decoding="async" style={{ width: "145px" }} width={224} height={332} src="assets/img/mask-img02-202x300.png" className="tt-img-mask" alt sizes="(max-width: 224px) 100vw, 224px" />
                                        </div>
                                        <div className="tt-box05__title">
                                            <div className="tt-text-01">Circuit Upgrades</div>
                                            <div className="tt-text-02">Upgrading circuits to support increased electrical loads and improve safety and performance in commercial and residential settings.</div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <a href="#" className="tt-box05" tabIndex={-1}>
                                        <div className="tt-box05__img">
                                            <img loading="lazy" decoding="async" style={{ width: "140px", objectFit: "cover", height: "175px" }} width={224} height={282} src="assets/img/layout01-img01.jpg" className="tt-img-main" alt />
                                            <img loading="lazy" decoding="async" style={{ width: "145px" }} width={224} height={332} src="assets/img/mask-img02-202x300.png" className="tt-img-mask" alt sizes="(max-width: 224px) 100vw, 224px" />
                                        </div>
                                        <div className="tt-box05__title">
                                            <div className="tt-text-01">Panel Upgrades and Replacements</div>
                                            <div className="tt-text-02">Upgrading or replacing electrical panels to meet modern standards and accommodate new wiring installations.</div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <a href="#" className="tt-box05" tabIndex={-1}>
                                        <div className="tt-box05__img">
                                            <img loading="lazy" decoding="async" style={{ width: "140px", objectFit: "cover", height: "175px" }} width={224} height={282} src="assets/img/NewElectronic/woman-changing-light-bulb-hanging-lamp-home-house-owner-cleaning-changing-vintage-light-bulb-house-main.jpg" className="tt-img-main" alt />
                                            <img loading="lazy" decoding="async" style={{ width: "145px" }} width={224} height={332} src="assets/img/mask-img02-202x300.png" className="tt-img-mask" alt sizes="(max-width: 224px) 100vw, 224px" />
                                        </div>
                                        <div className="tt-box05__title">
                                            <div className="tt-text-01">Lighting Repairs and Installation</div>
                                            <div className="tt-text-02">Repairing and installing lighting systems to enhance visibility and functionality in various settings.</div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <a href="#" className="tt-box05" tabIndex={-1}>
                                        <div className="tt-box05__img">
                                            <img loading="lazy" decoding="async" style={{ width: "140px", objectFit: "cover", height: "175px" }} width={224} height={282} src="assets/img/NewElectronic/pexels-trinh-tr-n-191284110-11645013.jpg" className="tt-img-main" alt />
                                            <img loading="lazy" decoding="async" style={{ width: "145px" }} width={224} height={332} src="assets/img/mask-img02-202x300.png" className="tt-img-mask" alt sizes="(max-width: 224px) 100vw, 224px" />
                                        </div>
                                        <div className="tt-box05__title">
                                            <div className="tt-text-01">Outlet and Switch Repairs</div>
                                            <div className="tt-text-02">Repairing and replacing faulty outlets and switches to ensure safe and reliable electrical access.</div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <a href="#" className="tt-box05" tabIndex={-1}>
                                        <div className="tt-box05__img">
                                            <img loading="lazy" decoding="async" style={{ width: "140px", objectFit: "cover", height: "175px" }} width={224} height={282} src="assets/img/mainslide-02.jpg" className="tt-img-main" alt />
                                            <img loading="lazy" decoding="async" style={{ width: "145px" }} width={224} height={332} src="assets/img/mask-img02-202x300.png" className="tt-img-mask" alt sizes="(max-width: 224px) 100vw, 224px" />
                                        </div>
                                        <div className="tt-box05__title">
                                            <div className="tt-text-01">Troubleshooting and Diagnostics</div>
                                            <div className="tt-text-02">In-depth diagnostics and troubleshooting to identify and resolve electrical issues efficiently.</div>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <a href="#" className="tt-box05" tabIndex={-1}>
                                        <div className="tt-box05__img">
                                            <img loading="lazy" decoding="async" style={{ width: "140px", objectFit: "cover", height: "175px" }} width={224} height={282} src="assets/img/layout01-img03.jpg" className="tt-img-main" alt />
                                            <img loading="lazy" decoding="async" style={{ width: "145px" }} width={224} height={332} src="assets/img/mask-img02-202x300.png" className="tt-img-mask" alt sizes="(max-width: 224px) 100vw, 224px" />
                                        </div>
                                        <div className="tt-box05__title">
                                            <div className="tt-text-01">Compliance and Safety Inspections</div>
                                            <div className="tt-text-02">Conducting inspections to ensure that all electrical systems meet regulatory standards and are safe for use.</div>
                                        </div>
                                    </a>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* Our Team End */}

                {/* Do you Need Help Start */}
                <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                    <div classname="container py-5">
                        <div className="section-indent">
                            <div className="tt-box01 js-init-bg lazyloaded" style={{ backgroundImage: 'url(assets/img/box01-bg-desktop.jpg)' }}>
                                <div className="container">
                                    <div className="tt-box01__holder">
                                        <div className="tt-box01__description">
                                            <h4 className="tt-box01__title">
                                                Do you <span className="tt-base-color">Need Help</span><br /> With Electrical<br /> Maintenance?
                                            </h4>
                                            <div className="tt-row-btn">
                                                <Link className="tt-btn btn__color01" to="/contact-us"><i class="fa-solid fa-phone pe-2"></i>Give Us a Call</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Do you Need Help End */}

                {/* What Our Clients Say Start */}
                <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                    <div classname="container py-5">
                        <div className="section-indent">
                            <div className="section__wrapper">
                                <div className="container container-md-fluid">
                                    <div className="tt-info-value row">
                                        <div className="tt-col-title col-md-4">
                                            <div className="tt-title">
                                                <img loading="lazy" decoding="async" width={125} height={125} src="assets/img/bg_marker.png" className="bg-marker" alt sizes="(max-width: 125px) 100vw, 125px" />
                                                <div className="tt-title__01">Our Statistics</div>
                                                <div className="tt-title__02">
                                                    Some Important Facts
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tt-box03 tt-box03__extraindent">
                            <div className="container container-md-fluid">
                                <div className="row no-gutters">
                                    <div className="col-md-7">
                                        <div className="tt-box03__content ">
                                            <Slider {...EmergencyServices}>
                                                <div className="item slick-slide slick-cloned" data-slick-index={-1} id aria-hidden="true" tabIndex={-1} style={{ width: 593 }}>
                                                    <div className="item__row">
                                                        <div className="tt-item__img">
                                                            <img loading="lazy" decoding="async" width={119} height={119} src="assets/img/man.png" className="attachment-full size-full" alt sizes="(max-width: 119px) 100vw, 119px" />
                                                        </div>
                                                        <div className="tt-item__title">
                                                            <div className="section-title text-left">
                                                                <div className="section-title__01">What Our Clients Say</div>
                                                                <div className="section-title__02">Outstanding Electrical Service for Fantasy Kids Cloths</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tt-item__content">
                                                        <blockquote>
                                                            VoltVibe House did an exceptional job with the electrical setup for our Fantasy Kids Cloths store. Their team handled the wiring and installation with great expertise, ensuring everything was done efficiently and to our exact specifications. The professionalism and attention to detail were impressive, and the project was completed on schedule. We’re thrilled with the results and will certainly rely on them for future projects!
                                                            <cite>- Store Manager, Fantasy Kids Cloths</cite>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                                <div className="item slick-slide slick-current slick-active" data-slick-index={0} aria-hidden="false" tabIndex={0} role="tabpanel" id="slick-slide10" aria-describedby="slick-slide-control10" style={{ width: 593 }}>
                                                    <div className="item__row">
                                                        <div className="tt-item__img">
                                                            <img loading="lazy" decoding="async" width={119} height={119} src="assets/img/woman.png" className="attachment-full size-full" alt sizes="(max-width: 119px) 100vw, 119px" />
                                                        </div>
                                                        <div className="tt-item__title">
                                                            <div className="section-title text-left">
                                                                <div className="section-title__01">What Our Clients Say</div>
                                                                <div className="section-title__02">Excellent Electrical Solutions for Dhara Puff
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tt-item__content">
                                                        <blockquote>
                                                            VoltVibe House provided top-notch electrical services for our Dhara Puff food brand location. Their team expertly handled the wiring and installation, ensuring that all our electrical needs were met with precision and efficiency. Their professionalism and commitment to quality were evident throughout the project. We are extremely satisfied with the outcome and look forward to working with them again for any future electrical requirements.
                                                            <cite>- Owner, Dhara Puff</cite>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                        <div className="tt-box03__img tt-visible-mobile lazyloaded" data-bg="assets/img/box03_img01.jpg" style={{ backgroundImage: 'url("assets/img/box03_img01.jpg")' }} />
                                        <div className="tt-box03__extra">
                                            <div className="tt-title">
                                                Emergency Service
                                            </div>
                                            <p>
                                                If this is an emergency outside of normal business hours, call us
                                            </p>
                                            <address><a href="tel: +91 9328134052"><i className="icon-telephone" />
                                                +91 9328134052 <br/> +91 261-4503763</a></address>
                                        </div>
                                    </div>
                                    <div className="tt-box03__img tt-visible-desktop lazyloaded" data-bg="assets/img/box03_img01.jpg" style={{ backgroundImage: 'url("assets/img/box03_img01.jpg")' }} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* What Our Clients Say End */}

                {/* Do you Need Help Start */}
                <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                    <div classname="container py-5">
                        <div class="section-indent">
                            <div className="tt-box01 lazyloaded" style={{ backgroundImage: 'url(assets/img/box01-bg02-desktop.jpg)' }}>
                                <div className="container">
                                    <div className="tt-box01__holder">
                                        <div className="tt-box01__description">
                                            <h4 className="tt-box01__title">
                                                Your <span className="tt-base-color">Best Option</span><br />in Electrical<br />Contractors 24/7					</h4>
                                            <p>
                                                Our skilled electricians excel in electrical setups, wiring, rewiring, and repairs for commercial spaces, franchise outlets, and residential properties.</p>
                                            <div className="tt-row-btn">
                                                <Link className="tt-btn btn__color01" to="/services">
                                                    <span className="icon-lightning" />
                                                    Explore services</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Do you Need Help End */}

                {/* Services Works Start */}
                <div className={`container-fluid py-5 wow fadeInUp`} style={{ position: "relative" }} data-wow-delay="0.1s">
                    <div class="works-step-bg"></div>
                    <div className="container py-5">
                        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                            <h1 name="h1tag" >Providing wide area of specialty services works
                            </h1>
                            <p className='pt-3'>The distracted by the readable content of a page when looking at its layout</p>
                        </div>
                        <div className="works-step-box-info-box">
                            <div className="works-step-box-info-shape-1 wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeInLeft' }} />
                            <div className="works-step-box-info-shape-2 wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="300ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '300ms', animationName: 'fadeInLeft' }} />
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    {/* works-step-single-box */}
                                    <div className="works-step-single-box wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="600ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '600ms', animationName: 'fadeInLeft' }}>
                                        <div className="works-step-single-img-box">
                                            <div className="works-step-single-img">
                                                <img src="assets/img/works-step-img-1.png" alt />
                                            </div>
                                            <div className="works-step-single-tag">
                                                <h4>01</h4>
                                            </div>
                                        </div>
                                        <div className="works-step-single-title text-center">
                                            <h3>Choose any of Services</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    {/* works-step-single-box */}
                                    <div className="works-step-single-box mx-auto wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="400ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '400ms', animationName: 'fadeInLeft' }}>
                                        <div className="works-step-single-img-box">
                                            <div className="works-step-single-img">
                                                <img src="assets/img/works-step-img-2.png" alt />
                                            </div>
                                            <div className="works-step-single-tag">
                                                <h4>02</h4>
                                            </div>
                                        </div>
                                        <div className="works-step-single-title text-center">
                                            <h3>Full Quotation Services</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    {/* works-step-single-box */}
                                    <div className="works-step-single-box ms-auto wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="200ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '200ms', animationName: 'fadeInLeft' }}>
                                        <div className="works-step-single-img-box">
                                            <div className="works-step-single-img">
                                                <img src="assets/img/works-step-img-3.png" alt />
                                            </div>
                                            <div className="works-step-single-tag">
                                                <h4>03</h4>
                                            </div>
                                        </div>
                                        <div className="works-step-single-title text-center">
                                            <h3>Get our best services</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="project-stats">
                            <div className="project-stats-content wow fadeInRight animated animated" data-wow-duration="1500ms" data-wow-delay="100ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInRight' }}>
                                <h2><span className="project-count wow animated fadeInLeft">3</span>+</h2>
                                <h5>Years of Experiences</h5>
                            </div>
                            <div className="project-stats-content wow fadeInRight animated animated" data-wow-duration="1500ms" data-wow-delay="300ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '300ms', animationName: 'fadeInRight' }}>
                                <h2><span className="project-count wow animated fadeInLeft">20</span>+</h2>
                                <h5>Completed Projects</h5>
                            </div>
                            <div className="project-stats-content wow fadeInRight animated animated" data-wow-duration="1500ms" data-wow-delay="500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeInRight' }}>
                                <h2><span className="project-count wow animated fadeInLeft">3000</span>+</h2>
                                <h5>Customers Satisfied</h5>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Services Works End */}

                {/* Founder Name Start */}
                <div className={`container-fluid py-5 wow fadeInUp`} style={{ position: "relative" }} data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="section-title text-center position-relative pb-3 mb-0 mx-auto" style={{ maxWidth: 600 }}>
                            <h1 name="h1tag" >Meet Our Founders
                            </h1>
                            <p className='pt-1'>Experts in electrical solutions with a commitment to quality and innovation
                            </p>
                        </div>
                        <div className="works-step-box-info-box">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12" style={{ display: "flex", justifyContent: "center" }}>
                                    {/* works-step-single-box */}
                                    <div className="works-step-single-box wow fadeInLeft animated animated founder-box-width" data-wow-duration="1500ms" data-wow-delay="400ms" style={{ marginLeft: "20px", marginRight: "20px", visibility: 'visible', animationDuration: '1500ms', animationDelay: '400ms', animationName: 'fadeInLeft' }}>
                                        <div className="works-step-single-img-box founder-box" style={{ border: "none" }}>
                                            <div className="works-step-single-img">
                                                <img src="assets/img/User/2.jpg"
                                                    className="founder-box"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: "10px"
                                                    }}
                                                    alt />
                                            </div>
                                        </div>
                                        <div className="works-step-single-title text-center">
                                            <h2>Juhil Dhameliya</h2>
                                        </div>
                                        <p className='text-center' style={{ fontSize: "20px" }}>(Co-Founder)</p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12" style={{ display: "flex", justifyContent: "center" }}>
                                    {/* works-step-single-box */}
                                    <div className="works-step-single-box wow fadeInLeft animated animated founder-box" data-wow-duration="1500ms" data-wow-delay="600ms" style={{ marginLeft: "20px", marginRight: "20px", visibility: 'visible', animationDuration: '1500ms', animationDelay: '600ms', animationName: 'fadeInLeft' }}>
                                        <div className="works-step-single-img-box founder-box" style={{ border: "none" }}>
                                            <div className="works-step-single-img">
                                                <img src="assets/img/User/1.jpg"
                                                    className="founder-box"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: "10px"
                                                    }}
                                                    alt />
                                            </div>
                                        </div>
                                        <div className="works-step-single-title text-center">
                                            <h2>Harsh Nasit</h2>
                                        </div>
                                        <p className='text-center' style={{ fontSize: "20px" }}>(CEO)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Founder Name Start */}
            </div>
        </div >
    );
}

export default Home;