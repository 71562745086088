import React from 'react';
import Titel from './UI/Title/Titel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function Services(props) {
    return (
        <>
            <div>
                {/* Navbar Start */}
                <div className="container-fluid position-relative p-0">
                    <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: 90 }}>
                        <div className="row py-5">
                            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-4 text-white animated zoomIn">Services</h1>
                                <a href className="h5 text-white">Home</a>
                                <i class="fa-regular fa-lightbulb px-2 text-white"></i>
                                <a href className="h5 text-white">Services</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`container-fluid wow fadeInUp `} data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="row g-6">
                            <div className="col-lg-6" style={{ minHeight: 500 }}>
                                <div className="position-relative h-100">
                                    <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/NewElectronic/technician-working-electrical-systemxa.jpg" style={{ objectFit: 'cover' }} />
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className='margin-left'>
                                    <div className="section-title position-relative pb-3 mb-3" style={{ textAlign: "start" }}>
                                        <h5 name="h5tag" className="text-uppercase">Our Services
                                        </h5>
                                        <h1 name="h1tag" >Responsive & Professional
                                        </h1>
                                    </div>
                                    <Titel>We are committed to delivering prompt and effective solutions, ensuring that your electrical needs are addressed with the utmost expertise and professionalism. Our team is always ready to respond quickly to any inquiries or emergencies, providing reliable and courteous service to meet your expectations.
                                    </Titel>
                                    <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                                        <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: 60, height: 60 }}>
                                            <i className="fa-solid fa-phone text-white" />
                                        </div>
                                        <div className="ps-4">
                                            <h5 className="mb-2">Call us today
                                            </h5>
                                            <a href='tel:+91 9328134052'><h4 className="text-primary mb-0"> +91 9328134052 / +91 261-4503763</h4></a>
                                        </div>
                                    </div>
                                    <Titel>
                                        We’re available 24/7, 365 days a year.
                                    </Titel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`container-fluid py-5 wow fadeInUp `} data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="row g-6">
                            <div className="col-lg-6 mt-3">
                                <div className="section-title position-relative pb-3 mb-3" style={{ textAlign: "start" }}>
                                    <h5 name="h5tag" className="text-uppercase">Why Choose Us?
                                    </h5>
                                    <h1 name="h1tag" >Our Electricians are:
                                    </h1>
                                </div>
                                <ul className="tt-list01">
                                    <li><i class="fa-solid fa-check"></i>Fully screened and background checked</li>
                                    <li><i class="fa-solid fa-check"></i>Rusted professionals we’d employ in our own homes</li>
                                    <li><i class="fa-solid fa-check"></i>Neat, clean, and uniformed</li>
                                    <li><i class="fa-solid fa-check"></i>Highly skilled and experienced</li>
                                    <li><i class="fa-solid fa-check"></i>Expertly trained in customer service</li>
                                    <li><i class="fa-solid fa-check"></i>Fully licensed, bonded, and insured</li>
                                    <li><i class="fa-solid fa-check"></i>Friendly and reliable</li>
                                </ul>
                            </div>
                            <div className="col-lg-6" style={{ minHeight: 500 }}>
                                <div className="position-relative h-100">
                                    <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/NewElectronic/pexels-trinh-tr-n-191284110-11645013.jpg" style={{ objectFit: 'cover' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Navbar End */}
                {/* Full Screen Search Start */}
                <div className="modal fade" id="searchModal" tabIndex={-1}>
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content" style={{ background: 'rgba(9, 30, 62, .7)' }}>
                            <div className="modal-header border-0">
                                <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body d-flex align-items-center justify-content-center">
                                <div className="input-group" style={{ maxWidth: 600 }}>
                                    <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
                                    <button className="btn btn-primary px-4"><i className="bi bi-search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Full Screen Search End */}

                {/* Quality of Services Start */}
                <div className={`container-fluid py-5 wow fadeInUp `} style={{ backgroundColor: "#F8F9FE", position: "relative" }} data-wow-delay="0.1s">
                    <div class="why-choose-three-shape-1"></div>
                    <div class="why-choose-three-shape-2"></div>
                    <div className="container py-5">
                        <div className="row g-6">
                            <div className="col-lg-6 mt-3">
                                <div className="section-title position-relative pb-3 mb-3" style={{ textAlign: "start" }}>
                                    <h1 name="h1tag" >Why Choose our quality of services
                                    </h1>
                                    <p className='pt-3'>We deliver exceptional craftsmanship and unwavering reliability, ensuring every project meets the highest standards of excellence.
                                    </p>
                                </div>
                                <div className="mb-4 wow fadeIn why-choose-three-content" data-wow-delay="0.6s">
                                    <div className="d-flex pe-4 align-items-center justify-content-center rounded why-choose-three-content-icon">
                                        <img src="assets/img/why-choose-three-content-icon.png" alt />
                                    </div>
                                    <div>
                                        <h5 className="mb-4">Call us today at +91 93281340520 we are ready to help 24/7 hour support.
                                        </h5>
                                        <Link to="/contact-us" class="thm-btn transparent-btn">Contact us<i class="fa-solid fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12" style={{ minHeight: 500 }}>
                                <div className="why-choose-three-right-box wow fadeInRight animated animated" data-wow-duration="1500ms" data-wow-delay="200ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '200ms', animationName: 'fadeInRight' }}>
                                    {/* why-choose-three-points-box */}
                                    <ul className="why-choose-three-points-box">
                                        <li>
                                            <div className="why-choose-three-points-shape" />
                                            <div className="why-choose-three-points-icon">
                                                <span className="icon-choose3" />
                                            </div>
                                            <div className="why-choose-three-points-details">
                                                <Link to="/about-us">24/7 Availability</Link>
                                                <p>The variations of but don't look even available slight many desktop.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="why-choose-three-points-shape" />
                                            <div className="why-choose-three-points-icon">
                                                <span className="icon-choose4" />
                                            </div>
                                            <div className="why-choose-three-points-details">
                                                <Link to="/about-us">Affordable Price</Link>
                                                <p>The variations of but don't look even available slight many desktop.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="why-choose-three-points-shape" />
                                            <div className="why-choose-three-points-icon">
                                                <span className="icon-choose5" />
                                            </div>
                                            <div className="why-choose-three-points-details">
                                                <Link to="/about-us">Free Estimation</Link>
                                                <p>The variations of but don't look even available slight many desktop.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* Quality of Services End */}
                {/* Experienced Start */}
                <div className="container-fluid py-5 wow fadeInUp experience" data-wow-delay="0.1s" style={{ position: "relative", backgroundColor: "#111829", color: "white" }}>
                    <div class="why-choose-three-shape-1"></div>
                    <div class="why-choose-three-shape-2"></div>
                    <div class="services-four-shape-3"></div>
                    <div class="services-four-shape-4"></div>
                    <div className="container py-5">
                        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                            <h1 name="h1tag" >Look at our Experienced Electricians Services
                            </h1>
                        </div>
                        <div className="row g-5 pb-5">
                            <div className="col-lg-4 col-md-6 wow zoomIn hover-service-item" data-wow-delay="0.3s">
                                <div className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center" style={{ position: "relative", visibility: 'visible', animationDuration: "1500ms", animationDelay: "200ms", animationName: "fadeInLeft" }}>
                                    <div className="service-icon">
                                        <i className="fa fa-search" />
                                    </div>
                                    <div class="services-four-single-three"></div>
                                    <h4 className="mb-3">Electrical Installation Services
                                    </h4>
                                    <p className="m-0">We offer precise and efficient electrical installations for both new setups and upgrades. Our services ensure safety and reliability.
                                    </p>
                                    <a className="btn btn-lg btn-primary rounded" href>
                                        <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn hover-service-item" data-wow-delay="0.6s">
                                <div className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center" style={{ position: "relative", visibility: 'visible', animationDuration: "1500ms", animationDelay: "200ms", animationName: "fadeInLeft" }}>
                                    <div className="service-icon">
                                        <i className="fa fa-chart-pie" />
                                    </div>
                                    <div class="services-four-single-three"></div>
                                    <h4 className="mb-3">Wiring and Rewiring</h4>
                                    <p className="m-0">Expert wiring and rewiring services to ensure safe, reliable, and efficient electrical systems in your home or business.
                                    </p>
                                    <a className="btn btn-lg btn-primary rounded" href>
                                        <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn hover-service-item" data-wow-delay="0.9s">
                                <div className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center" style={{ position: "relative", visibility: 'visible', animationDuration: "1500ms", animationDelay: "200ms", animationName: "fadeInLeft" }}>
                                    <div className="service-icon">
                                        <i className="fa fa-code" />
                                    </div>
                                    <div class="services-four-single-three"></div>
                                    <h4 className="mb-3">Emergency Electrical Support</h4>
                                    <p className="m-0">Rapid response for urgent electrical issues, ensuring quick and effective solutions to restore safety and functionality.</p>
                                    <a className="btn btn-lg btn-primary rounded" href>
                                        <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn hover-service-item" data-wow-delay="0.3s">
                                <div className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center" style={{ position: "relative", visibility: 'visible', animationDuration: "1500ms", animationDelay: "200ms", animationName: "fadeInLeft" }}>
                                    <div className="service-icon">
                                        <i className="fab fa-android" />
                                    </div>
                                    <div class="services-four-single-three"></div>
                                    <h4 className="mb-3">Maintenance and Repairs</h4>
                                    <p className="m-0">Comprehensive maintenance and repair services to keep your electrical systems running smoothly and address any issues promptly.</p>
                                    <a className="btn btn-lg btn-primary rounded" href>
                                        <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn hover-service-item" data-wow-delay="0.6s">
                                <div className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center" style={{ position: "relative", visibility: 'visible', animationDuration: "1500ms", animationDelay: "200ms", animationName: "fadeInLeft" }}>
                                    <div className="service-icon">
                                        <i className="fa fa-search" />
                                    </div>
                                    <div class="services-four-single-three"></div>
                                    <h4 className="mb-3">Lighting Design and Installation</h4>
                                    <p className="m-0">Lighting Design and Installation</p>
                                    <a className="btn btn-lg btn-primary rounded" href>
                                        <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn hover-service-item" data-wow-delay="0.9s">
                                <div className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center" style={{ position: "relative", visibility: 'visible', animationDuration: "1500ms", animationDelay: "200ms", animationName: "fadeInLeft" }}>
                                    <div className="service-icon">
                                        <i className="fa fa-search" />
                                    </div>
                                    <div class="services-four-single-three"></div>
                                    <h4 className="mb-3">Electrical Safety Inspections</h4>
                                    <p className="m-0">Thorough inspections to identify potential hazards and ensure your electrical systems comply with safety standards.</p>
                                    <a className="btn btn-lg btn-primary rounded" href>
                                        <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Experienced End */}

                {/* Services Works Start */}
                <div className={`container-fluid py-5 wow fadeInUp`} style={{ position: "relative" }} data-wow-delay="0.1s">
                    <div class="works-step-bg"></div>
                    <div className="container py-5">
                        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                            <h1 name="h1tag" >Providing wide area of specialty services works
                            </h1>
                            <p className='pt-3'>The distracted by the readable content of a page when looking at its layout</p>
                        </div>
                        <div className="works-step-box-info-box">
                            <div className="works-step-box-info-shape-1 wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeInLeft' }} />
                            <div className="works-step-box-info-shape-2 wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="300ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '300ms', animationName: 'fadeInLeft' }} />
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    {/* works-step-single-box */}
                                    <div className="works-step-single-box wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="600ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '600ms', animationName: 'fadeInLeft' }}>
                                        <div className="works-step-single-img-box">
                                            <div className="works-step-single-img">
                                                <img src="assets/img/works-step-img-1.png" alt />
                                            </div>
                                            <div className="works-step-single-tag">
                                                <h4>01</h4>
                                            </div>
                                        </div>
                                        <div className="works-step-single-title text-center">
                                            <h3>Choose any of Services</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    {/* works-step-single-box */}
                                    <div className="works-step-single-box mx-auto wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="400ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '400ms', animationName: 'fadeInLeft' }}>
                                        <div className="works-step-single-img-box">
                                            <div className="works-step-single-img">
                                                <img src="assets/img/works-step-img-2.png" alt />
                                            </div>
                                            <div className="works-step-single-tag">
                                                <h4>02</h4>
                                            </div>
                                        </div>
                                        <div className="works-step-single-title text-center">
                                            <h3>Full Quotation Services</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    {/* works-step-single-box */}
                                    <div className="works-step-single-box ms-auto wow fadeInLeft animated animated" data-wow-duration="1500ms" data-wow-delay="200ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '200ms', animationName: 'fadeInLeft' }}>
                                        <div className="works-step-single-img-box">
                                            <div className="works-step-single-img">
                                                <img src="assets/img/works-step-img-3.png" alt />
                                            </div>
                                            <div className="works-step-single-tag">
                                                <h4>03</h4>
                                            </div>
                                        </div>
                                        <div className="works-step-single-title text-center">
                                            <h3>Get our best services</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="project-stats">
                            <div className="project-stats-content wow fadeInRight animated animated" data-wow-duration="1500ms" data-wow-delay="100ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInRight' }}>
                                <h2><span className="project-count wow animated fadeInLeft">3</span>+</h2>
                                <h5>Years of Experiences</h5>
                            </div>
                            <div className="project-stats-content wow fadeInRight animated animated" data-wow-duration="1500ms" data-wow-delay="300ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '300ms', animationName: 'fadeInRight' }}>
                                <h2><span className="project-count wow animated fadeInLeft">20</span>+</h2>
                                <h5>Completed Projects</h5>
                            </div>
                            <div className="project-stats-content wow fadeInRight animated animated" data-wow-duration="1500ms" data-wow-delay="500ms" style={{ visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeInRight' }}>
                                <h2><span className="project-count wow animated fadeInLeft">3000</span>+</h2>
                                <h5>Customers Satisfied</h5>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Services Works End */}
            </div >

        </>
    );
}

export default Services;