import React, { useState, useEffect } from 'react';

const WhatsappTopButton = () => {

    return (
        <a
            className="scroll-to-top"
            style={{ display: 'inline' }}
            href="https://wa.me/919328134052"
            target="_blank"
            rel="noopener noreferrer"
        >
            <i className="fa-brands fa-whatsapp"></i>
        </a>
    );
};

export default WhatsappTopButton;
