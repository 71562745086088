import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header(props) {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <>
            {/* Spinner Start */}
            {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner" />
            </div> */}
            {/* Spinner End */}

            {/* Topbar Start */}
            <div className={`container-fluid bg-dark px-5 d-none d-lg-block`}>
                <div className="row gx-0">
                    <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center" style={{ height: 45 }}>
                            <small className={`me-3 text-light`}><i className="fa fa-map-marker-alt me-2" />342, Meridian Business Center, Lajamni Chowk, Mota Varachha, Surat</small>
                            <small className={`me-3 text-light`}><i className="fa-solid fa-phone me-2" />+91 9328134052</small>
                            <small className={`text-light`}><i className="fa fa-envelope-open me-2" />voltvibehouse@gmail.com</small>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center text-lg-end">
                        <div className="d-inline-flex align-items-center" style={{ height: 45 }}>
                            <a className={`btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2`} href="mailto:voltvibehouse@gmail.com"><i className="bi bi-envelope-open"></i></a>
                            <a className={`btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2`} href="https://www.instagram.com/voltvibe.house?igsh=MXJmdzh4NmF2NGxoNA%3D%3D"><i class="fa-brands fa-instagram"></i></a>
                            <a className={`btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2`} href="https://wa.me/919328134052"><i class="fa-brands fa-whatsapp"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Topbar End */}

            {/* Navbar Start */}
            <div className="container-fluid position-relative p-0">
                <nav className="navbar navbar-expand-lg navbar-dark py-3 py-lg-0" style={{ backgroundColor: "#fff" }}>
                    <a href="/" className="navbar-brand p-0">
                        <img src='assets/img/Logo-new.png' style={{ width: "160px" }} />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="fa fa-bars" />
                    </button>
                    <div className="collapse navbar-collapse ms-5 me-5" id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                            <li>
                                <Link
                                    to="/"
                                    className={isActive('/') ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/about-us"
                                    className={isActive('/about-us') ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                >
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/services"
                                    className={isActive('/services') ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                >
                                    Services
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contact-us"
                                    className={isActive('/contact-us') ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                >
                                    Contact
                                </Link>
                            </li>
                        </div>
                    </div>
                    <div className="align-items-center ms-5 wow fadeIn header-call" data-wow-delay="0.6s">
                        <div className="bg-primary d-flex align-items-center justify-content-center" style={{ width: 50, height: 50, borderRadius: "9999px" }}>
                            <i className="fa-solid fa-phone text-white" />
                        </div>
                        <div className="ps-4">
                            <h6 className="mb-2" style={{ color: "#303442" }}>Call us today
                            </h6>
                            <a href='tel:+91 9328134052'><h4 className="text-primary mb-0" style={{ fontSize: "20px" }}> +91 9328134052 / +91 261-4503763</h4></a>
                        </div>
                    </div>
                </nav>
            </div>
            {/* Navbar End */}
        </>
    );
}

export default Header;