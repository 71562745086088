import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router';

function About(props) {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div>
                {/* Navbar Start */}
                <div className="container-fluid position-relative p-0">
                    <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: 90 }}>
                        <div className="row py-5">
                            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-4 text-white animated zoomIn">About Us</h1>
                                <a href className="h5 text-white">Home</a>
                                <i class="fa-regular fa-lightbulb px-2 text-white"></i>
                                <a href className="h5 text-white">About</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Navbar End */}

                {/* Full Screen Search Start */}
                <div className="modal fade" id="searchModal" tabIndex={-1}>
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content" style={{ background: 'rgba(9, 30, 62, .7)' }}>
                            <div className="modal-header border-0">
                                <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body d-flex align-items-center justify-content-center">
                                <div className="input-group" style={{ maxWidth: 600 }}>
                                    <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
                                    <button className="btn btn-primary px-4"><i className="bi bi-search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Full Screen Search End */}

                {/* About Start */}
                <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container pt-5">
                        <div className="layout01 layout01__revers layout01__small-layout layout01__img-more">
                            <div className="layout01__bg-marker"><img decoding="async" width={125} height={125} src="assets/img/bg_marker02.png" className="attachment-full size-full" alt sizes="(max-width: 125px) 100vw, 125px" /></div>
                            <div className="layout01__img">
                                <div className="tt-img-main">
                                    <img fetchpriority="high" decoding="async" width={720} height={552} src="assets/img/NewElectronic/ai-generated-8810296.jpg" className="attachment-full size-full" alt sizes="(max-width: 720px) 100vw, 720px" />					</div>
                                <div className="tt-img-more left-bottom right-changes" >
                                    <img decoding="async" width={436} src="assets/img/NewElectronic/man-working-escalator.jpg" className="attachment-full size-full" alt sizes="(max-width: 436px) 100vw, 436px" />					</div>
                            </div>
                            <div className="layout01__content">
                                <div className="layout01__content-wrapper">
                                    <div className="section-title" style={{ textAlign: "start" }}>
                                        <h5 className="fw-bold text-primary text-uppercase">Over 25 Years Experience</h5>
                                        <h1>Experienced and Reliable Technicians</h1 >
                                    </div>
                                    <p>
                                        Established in 2002, we are a locally owned and operated business committed to delivering exceptional electrical services. Our team of highly trained and courteous technicians ensures reliable, efficient, and professional service for every client. Our mission is to provide dependable and trustworthy solutions, reflecting our dedication to excellence in every project we undertake.
                                    </p>
                                    <div className="tt-box04-wrapper row">
                                        <div className="col-6">
                                            <div className="tt-box04">
                                                <div className="tt-box04__figure"><i class="fa-solid fa-award"></i></div>
                                                <div className="tt-box04__content">
                                                    <div className="tt-title">50+</div>
                                                    <p>
                                                        Skilled &amp; Certified Electricians</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* About End */}

                {/* Our Advantages Start */}
                <div className="container-fluid pb-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div>
                            <div className="section-title max-width-01">
                                <h5 className="fw-bold text-primary text-uppercase">Our Advantages</h5>
                                <h1 >Reasons You Should Call Us</h1 >
                                <div className="section-title__03">
                                    Electrician is your single source for a complete range of high-quality
                                    electrical services, including design/build, engineering and maintenance.</div>
                            </div>
                            <div className="row tt-services-promo__list justify-content-center">
                                <div className="col-sm-6 col-lg-4  tt-item">
                                    <div className="tt-services-promo">
                                        <div className="tt-value tt-value__indent">1</div>
                                        <div className="tt-wrapper">
                                            <div className="tt-col-icon" ><i class="fas fa-clock icon"></i></div>

                                            <div className="tt-col-layout">
                                                <div className="tt-title">
                                                    24/7 Emergency Services							</div>
                                                <p>
                                                    24/7 emergency electrician you can trust.							</p>
                                            </div>
                                        </div>
                                        <div className="tt-bg-marker" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4  tt-item">
                                    <div className="tt-services-promo">
                                        <div className="tt-value tt-value__indent">2</div>
                                        <div className="tt-wrapper">
                                            <div className="tt-col-icon  icon-tool2" ><i class="fas fa-thumbs-up icon"></i></div>
                                            <div className="tt-col-layout">
                                                <div className="tt-title">
                                                    Free Estimates							</div>
                                                <p>
                                                    We provide complimentary estimates for electrical additions and replacements.</p>
                                            </div>
                                        </div>
                                        <div className="tt-bg-marker" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4  tt-item">
                                    <div className="tt-services-promo">
                                        <div className="tt-value tt-value__indent">3</div>
                                        <div className="tt-wrapper">
                                            <div className="tt-col-icon  icon-price-tag" ><i class="fas fa-dollar-sign icon"></i></div>
                                            <div className="tt-col-layout">
                                                <div className="tt-title">
                                                    affordable rates
                                                </div>
                                                <p>
                                                    We are committed to providing the most competitive prices in the market.</p>
                                            </div>
                                        </div>
                                        <div className="tt-bg-marker" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Our Advantages End */}
            </div>
        </>
    );
}

export default About;