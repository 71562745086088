import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import Contact from '../pages/container/Contact';
import About from '../pages/container/About';
import Services from '../pages/container/Services';
import Home from '../pages/container/Home';
import Header from '../pages/components/Header';
import Footer from '../pages/components/Footer';
import WhatsappTopButton from '../pages/components/WhatsappTopButton';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function AllRoutes(props) {
    return (
        <>
            <Header />
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/contact-us' element={<Contact />} />
                <Route path='/about-us' element={<About />} />
                <Route path='/services' element={<Services />} />
            </Routes>
            <Footer />
            <WhatsappTopButton />

        </>
    );
}

export default AllRoutes;