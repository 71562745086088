import React from 'react';
import 'leaflet/dist/leaflet.css';
import Input from './UI/Input/Input';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Initialize EmailJS with your user ID
emailjs.init('i_TKHtpJTFETkuPzl');

function Contact(props) {

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_me7ka4w', 'template_ex7zk4i', e.target, 'i_TKHtpJTFETkuPzl')
            .then((result) => {
                console.log(result.text);
                toast.success("Email sent successfully", { autoClose: 2000 });
            }, (error) => {
                console.log(error.text);
                toast.error("Failed to send email", { autoClose: 2000 });
            });

        e.target.reset();
    };

    return (
        <>
            <div>
                <ToastContainer />
                {/* Navbar Start */}
                <div className="container-fluid position-relative p-0">
                    <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: 90 }}>
                        <div className="row py-5">
                            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-4 text-white animated zoomIn">Get connected with us for <br /> top-notch electrical solutions!</h1>
                                <a href className="h5 text-white">Home</a>
                                <i class="fa-regular fa-lightbulb px-2 text-white"></i>
                                <a href className="h5 text-white">Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Navbar End */}
                {/* Full Screen Search Start */}
                <div className="modal fade" id="searchModal" tabIndex={-1}>
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content" style={{ background: 'rgba(9, 30, 62, .7)' }}>
                            <div className="modal-header border-0">
                                <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body d-flex align-items-center justify-content-center">
                                <div className="input-group" style={{ maxWidth: 600 }}>
                                    <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
                                    <button className="btn btn-primary px-4"><i className="bi bi-search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Full Screen Search End */}
                {/* Contact Start */}
                <div className="container-fluid pt-4 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container">
                        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                            <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
                            <h1 >Get In Touch with Us</h1>
                            <p className='pt-3'>Reach out to us for any inquiries, service requests, or to schedule a consultation. Our team is ready to assist you with expert advice and prompt service.
                            </p>
                        </div>
                        <div className="row g-5 mb-5">
                            <div className="col-lg-4">
                                <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: 60, height: 60 }}>
                                        <i className="fa-solid fa-phone text-white" />
                                    </div>
                                    <div className="ps-4">
                                        <h5 className="mb-2">Call to ask any question</h5>
                                        <h6 className="text-primary mb-0">+91 9328134052 / +91 261-4503763</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: 60, height: 60 }}>
                                        <i className="fa fa-envelope-open text-white" />
                                    </div>
                                    <div className="ps-4">
                                        <h5 className="mb-2">Email to get free quote</h5>
                                        <h6 className="text-primary mb-0">voltvibehouse@gmail.com</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: 60, height: 60 }}>
                                        <i className="fa fa-map-marker-alt text-white" />
                                    </div>
                                    <div className="ps-4">
                                        <h5 className="mb-2">Visit our office</h5>
                                        <h6 className="text-primary mb-0">342, Meridian Business Center <br /> Lajamni Chowk, Mota Varachha, Surat</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                                <form onSubmit={sendEmail}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <Input type="text" placeholder="Your Name" name="from_name" />
                                        </div>
                                        <div className="col-md-6">
                                            <Input type="number" placeholder="Phone Number" name="user_phone" />
                                        </div>
                                        <div className="col-md-6">
                                            <Input type="email" placeholder="Email Address" name="email_id" />
                                        </div>
                                        <div className="col-md-6">
                                            <Input type="text" placeholder="Address" name="user_address" />
                                        </div>
                                        <div className="col-12">
                                            <textarea className="form-control border-0 bg-light px-4 py-3" placeholder="Services Description" rows={4} name="message" />
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit">Make An Appointment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-6 wow slideInUp map-width" data-wow-delay="0.6s">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.939837076264!2d72.87434987587633!3d21.23423418068457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f75e005ee1f%3A0xe18ab41f7f3c5f5d!2sSumeru%20City%20Mall!5e0!3m2!1sen!2sin!4v1722686361507!5m2!1sen!2sin" height={350} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Contact End */}
                {/* Vendor Start */}
                <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container py-5 mb-5">
                        <div className="bg-white">
                            <div className="owl-carousel vendor-carousel">
                                <img src="img/vendor-1.jpg" alt />
                                <img src="img/vendor-2.jpg" alt />
                                <img src="img/vendor-3.jpg" alt />
                                <img src="img/vendor-4.jpg" alt />
                                <img src="img/vendor-5.jpg" alt />
                                <img src="img/vendor-6.jpg" alt />
                                <img src="img/vendor-7.jpg" alt />
                                <img src="img/vendor-8.jpg" alt />
                                <img src="img/vendor-9.jpg" alt />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Vendor End */}
            </div >
        </>
    );
}

export default Contact;
