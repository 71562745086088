import React from 'react';
import Input from '../container/UI/Input/Input';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <>
            {/* Footer Start  */}
            <div>
                <div className="container-fluid bg-dark text-light">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-4 col-md-6">
                                <div className="d-flex flex-column justify-content-center h-100 p-4">
                                    <a href="/" className="navbar-brand">
                                        <img src='assets/img/VoltVibeHouse_black_to_white.png' style={{ width: "160px", heigth: "58px" }} />
                                    </a>
                                    <p style={{ fontSize: "14px" }} className="mt-3" >At VoltVibe House, we specialize in comprehensive electrical solutions for commercial and residential spaces. From full wiring setups and rewiring projects to expert repairs and maintenance, our team is dedicated to delivering top-notch service with precision and reliability. With a commitment to quality and customer satisfaction, we ensure every project is completed efficiently and to the highest standards. Explore our services and see how we can power your next project.</p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6">
                                <div className="row gx-5">
                                    <div className="col-lg-6 col-md-12 pt-0 pt-lg-5 mb-5">
                                        <div className="section-title section-title-sm position-relative pb-3 mb-4" style={{ textAlign: "start" }}>
                                            <h3 className="text-light mb-0">Explore</h3>
                                        </div>
                                        <div className="link-animated d-flex flex-column justify-content-start">
                                            <Link className="text-light mb-2" to="/"><i className="bi bi-arrow-right text-primary me-2" />Home</Link>
                                            <Link className="text-light mb-2" to="/about-us"><i className="bi bi-arrow-right text-primary me-2" />About Us</Link>
                                            <Link className="text-light mb-2" to="/services"><i className="bi bi-arrow-right text-primary me-2" />Our Services</Link>
                                            <Link className="text-light" to="/contact-us"><i className="bi bi-arrow-right text-primary me-2" />Contact Us</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 pt-5 mb-5">
                                        <div className="section-title section-title-sm position-relative pb-3 mb-4" style={{ textAlign: "start" }}>
                                            <h3 className="text-light mb-0">Get In Touch</h3>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <i className="bi bi-geo-alt text-primary me-2" />
                                            <p className="mb-0">342, Meridian Business Center, Lajamni Chowk, Mota Varachha, Surat</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <i className="bi bi-envelope-open text-primary me-2" />
                                            <p className="mb-0">voltvibehouse@gmail.com</p>
                                        </div>
                                        <div className="d-flex mb-2">
                                            <i className="bi bi-telephone text-primary me-2" />
                                            <p className="mb-0">+91 9328134052</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center-start mb-2">
                                            <i className="fa-solid fa-tty text-primary me-2" />
                                            <p className="mb-0">+91 261-4503763</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center-start mb-2">
                                            <i className="fa-brands fa-instagram text-primary me-2"></i>
                                            <p className="mb-0">voltvibe.house</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer End */}
        </>

    );
}

export default Footer;